import { render, staticRenderFns } from "./DialogSubscribers.vue?vue&type=template&id=52761536&scoped=true&"
import script from "./DialogSubscribers.vue?vue&type=script&lang=ts&"
export * from "./DialogSubscribers.vue?vue&type=script&lang=ts&"
import style0 from "./DialogSubscribers.vue?vue&type=style&index=0&id=52761536&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52761536",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VImg,VList,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSheet,VWindow,VWindowItem})

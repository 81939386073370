































































































import BaseDialog from '@/components/BaseDialog.vue'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import SearchView from '@/components/SearchView.vue'
import Avatar from "@/components/Avatar.vue";
import {channelsStore} from "@/store/modules/channels";
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';

@Component({
  name: 'dialog-subscribers',
  components: {BaseDialog, SearchView, Avatar, AvatarWithStatus},
})
export default class DialogSubscribers extends Vue {
  @Prop() value!: boolean

  channelId:any = null;
  subscriberList?: any[] | null = []
  subscriberListOriginal?: any[] | null = []

  contactList: { data: any, selected: boolean }[] = []
  contactListOriginal: { data: any, selected: boolean }[] = []
  loading: boolean = false;
  loadingRemove: boolean = false;

  stepSubscribers: string = 'step-subscribers'
  stepContacts: string = 'step-contacts'  
  currentStep: string = this.stepSubscribers  

  get show() {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }  

  get buttonText() {    
    switch (this.currentStep) {
      case this.stepSubscribers:
        return 'Add subscriber';
      case this.stepContacts:
        return 'Save';
      default:
        return '';
    }
  }

  get title() {    
    switch (this.currentStep) {
      case this.stepSubscribers:
        return `${this.subscribersCount} subscribers`;
      case this.stepContacts:
        return 'Add subscribers';
      default:
        return '';
    }
  }
  
  dobSubscriber(subscriber) {
    let dob = '';    
    const contact =  this.subscribers?.filter(item => item.id == subscriber.id 
    && item.dob != '' 
    && item.allowedViewDOB);

    if(contact && contact.length > 0){
      dob = contact[0].dob;
    }    
    return dob;
  }

  dobContact(contact) {
    let dob = '';
    if(contact?.allowedViewDOB && contact?.dob){
      dob = contact?.dob;    
    }    
    return dob;
  }
  

  isOnline(contact) {      
    const status = (contact?.online === true) ? true : false; 
    console.log('status: ' +  status)
    return status;    
  }
  
  get businessChannels() {
    return channelsStore.businessChannels
  }

  @Watch('businessChannels')
  onItemsChanged(value, oldValue) {    
    this.initData()
  }
 
  async initData() {

    if(this.channelId){
      
      // subscribers
      if(this.stepSubscribers){
        await channelsStore.loadChannelMembers(this.channelId)
        this.subscriberList = Object.assign([], this.subscribers);
        this.subscriberListOriginal = Object.assign([], this.subscriberList);
      }

      // contacts
      if(this.stepContacts){
        await channelsStore.loadNonChannelMembers(this.channelId)
        this.contactList = Object.assign([], this.contacts).map((item) => {
          return { data: item, selected: false };
        });
        this.contactListOriginal = Object.assign([], this.contactList);
      }  
    }
  }

  // subscribers //////////////////////////////////////////////////////////////////////////////////
  get subscribers() {
    return channelsStore.selectedChannelMembers
  }

  get subscribersCount() {    
    return this.subscribers?.length || 0
  }

  onSearchSubscriber(term) { 
    if (!term) {
      this.subscriberList = this.subscriberListOriginal;
      return;
    }
    
    const searchString = term.toLowerCase();
    this.subscriberList = this.subscriberListOriginal?.filter((item) => {
        return item.name.toLowerCase().includes(searchString)
    });
  }
 
  async onRemoveSubscriber(subscriber) {  
    
    this.loadingRemove = true;  

    try{
      const subscriberId = subscriber.id;    
      if(subscriberId){
        const status = await channelsStore.unSubscribeChannel(subscriberId)      
        this.initData();
      }
    } catch (err) {
      console.log(`onRemoveSubscriber. error: ${err}`);
    }
   
    this.loadingRemove = false;
  }



  // contacts ////////////////////////////////////////////////////////////////////////////////////
  get contacts() {    
    return channelsStore.nonSelectedChannelMembers
  } 

  get selectedContacts() {
    return this.contactList.filter((item) => item.selected).map((item) => item.data);
  }

  onSearchContact(term) {
    if (!term) {
      this.contactList = this.contactListOriginal;
      return;
    }

    const searchString = term.toLowerCase();
    this.contactList = this.contactListOriginal.filter((item) => {
      return item.data.name.toLowerCase().includes(searchString)
    });
  }

  onContactSelected(contact) {    
    contact.selected = !contact.selected;
  }

  async onAddSubscriber() {
    if (!this.selectedContacts.length) {
      console.log('No subscriber selected');
      return;
    }    
    
    try {
      this.loading = true;
      await channelsStore.subscribeChannel(this.selectedContacts);
      this.initData();
      this.currentStep = this.stepSubscribers;

      //clear search
      const searchContact = this.$refs.searchContact as SearchView;
      searchContact.searchValue = '';
    } catch (err) {
      console.log(`Add subscriber. error: ${err}`);
    }
    this.loading = false;
  }


  // common //////////////////////////////////////////////////////////////////////////////////
  onClientView(id) {
    this.$router.push({name: 'customer-profile', params: {customerId: id, type: 'all'}});
  }

  private created() {
    this.channelId = channelsStore.selectedChannel?.id;
    this.initData();   
  }
}
